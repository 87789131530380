<template>
  <div>
    <v-dialog width="500px" v-model="deleteDialog">
      <v-card>
        <v-card-title class="red white--text">
          اخطار
        </v-card-title>
        <v-card-text class="mt-4">
          <div class="textMessage">
            با پاک کردن کلمه کلیدی، دیتاهای این کلمه کلیدی هم پاک می‌شود و شما دیتادیگری روی این کلمه کلیدی دریافت
            نمیکنید. از این کار مطمئن هستید؟
          </div>
          <div class="d-flex mt-5">
            <v-btn @click="deleteKeyword" color="red white--text" class="font-weight-bold" elevation="0">
              پاک کردن
            </v-btn>
            <v-btn class="mr-4 font-weight-bold" elevation="0" color="secondary" text>
              لغو
            </v-btn>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-form ref="keyword">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" lg="6" class="pa-0 ma-0">
          <div class="">
            <div class="inputBox">
              <template v-if="keyword.hasOwnProperty('id')">
                {{ keyword.id }}
                -
              </template>
              شبکه‌ اجتماعی
              <span class="mr-2" :class="`${keyword.state}_state`">
                  {{ keyword.state | stateFilter }}
                </span>
              <div class="d-flex flex-wrap">
                <div style="width: 220px">
                  <v-select :rules="rules.required" outlined dense class="mt-3 ma-0 pa-0" :items="allowedSubscription"
                            item-text="name"
                            item-value="id" v-model="social_media_id">
                    <template v-slot:item="{item}">
                      <div style="font-size: 13px">
                        {{ item.name }}
                        <template v-if="item.hasOwnProperty('state')">
                          <v-chip color="primary" x-small class="mr-2">
                            بزودی
                          </v-chip>
                        </template>
                      </div>
                    </template>
                  </v-select>

                </div>
                <div class="mt-6 mr-5 countData flex-grow-1"
                     v-if="data.hasOwnProperty('id') && $route.name != 'keywordDetail'">
                  (
                  <span class="font-weight-bold">
                        {{ data.data_count }}
                      </span>
                  دیتای دریافت شده
                  )
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6"></v-col>
        <v-col cols="12" lg="3" class="pa-0 ma-0">
          <div class="inputBox">
            کلید‌واژه
            <v-text-field :rules="rules.required" v-model="data.pattern" outlined dense class="mt-3"></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="pa-0 ma-0 centering">
          <div class="inputBox  mt-1 pr-lg-10">
            <v-checkbox v-model="data.search_post" label="جست‌و‌جو در پست‌ها"></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="pa-0 ma-0 centering">
          <div class="inputBox mt-1">
            <v-checkbox v-model="data.search_commnet" label="جست‌و‌جو در کامنت‌ها"></v-checkbox>
          </div>
        </v-col>
        <template v-if="social_media_id != 9">
          <v-col cols="12"></v-col>
          <v-col cols="12" lg="3">
            <div class="inputBox">
              شامل این زبان ها باشد
              <v-select persistent-hint hint="حداکثر 3 زبان انتخاب کنید" :items="languages" v-model="data.included_lang"
                        @change="limitSelection" multiple outlined dense item-text="name"
                        item-value="code"></v-select>
            </div>
          </v-col>
          <v-col cols="12" lg="3">
            <div class="inputBox">
              شامل این زبان ها نباشد
              <v-select persistent-hint hint="حداکثر 3 زبان انتخاب کنید" :items="languages" v-model="data.excluded_lang"
                        @change="limitSelectionExclude" multiple outlined dense item-text="name"
                        item-value="code"></v-select>
            </div>
          </v-col>
          <v-col cols="12" lg="6"></v-col>
        </template>
        <v-col cols="12" lg="3" class="ml-5 pa-0 mt-3 ma-0">
          <div class="inputBox">
            شامل این کلمات نباشد
            <v-text-field v-model="data.exclude_keyword" hint="کلمات را با '،' از هم جدا کنید" persistent-hint
                          outlined dense
                          class="mt-3"></v-text-field>
          </div>
        </v-col>
        <template v-if="social_media_id != 9">
          <v-col cols="12" lg="3" v-if="social_media_id == 10">
            <div class="inputBox ">
              لینک گروه‌های عمومی
              <v-text-field v-model="data.public_group_link" hint="" persistent-hint
                            outlined dense
                            class="mt-3"></v-text-field>
            </div>
          </v-col>
          <template v-if="social_media_id != 5 && social_media_id != 1 && social_media_id != 10">
            <v-col cols="12" lg="3" class=" ml-5 pa-0 mt-3 ma-0">
              <div class="inputBox">
                شامل این کاربران باشد
                <v-text-field persistent-hint hint="آیدی کاربران را با '،' از هم جدا کنید" placeholder="آیدی کاربران"
                              v-model="data.include_user" outlined dense class="mt-3"></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" lg="3" class="pa-0 mt-3 ma-0">
              <div class="inputBox">
                شامل این کاربران نباشد
                <v-text-field persistent-hint hint="آیدی کاربران را با '،' از هم جدا کنید" v-model="data.exclude_user"
                              placeholder="آیدی کاربران" outlined dense class="mt-3"></v-text-field>
              </div>
            </v-col>
          </template>
          <v-col cols="12">
            <div class="inputBox mt-1">
              <v-switch v-model="data.status" :value="true" dense flat inset label="فعال"></v-switch>
            </div>
          </v-col>
        </template>
      </v-row>
      <div class="py-5"
           v-if="keyword.hasOwnProperty('user_keyword_rejection') && keyword.user_keyword_rejection.length != 0 && keyword.state == 'rejected'">
        <div class="font-weight-bold">
          جزییات تایید نشدن کلمه کلیدی
        </div>
        <div class="mt-5">
          <v-expansion-panels class="elevation-0">
            <v-expansion-panel class="elevation-0">
              <v-expansion-panel-header class="elevation-0">
                {{ rejection.created_at | dateFilter }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="elevation-0">
                {{ rejection.message }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

      <div class="d-flex justify-end">
        <div class="mr-3" v-if="data.hasOwnProperty('id') && $route.name != 'keywordDetail'">
          <v-btn small color="primary" :to="`/dashboard/keywords/${this.data.id}`" class="font-weight-bold"
                 elevation="0"
                 style="border-radius: 10px">
            دیتا‌ها
          </v-btn>
        </div>
        <div class="mr-3" v-if="data.hasOwnProperty('id')">
          <v-btn small :to="{name:'AddReport',query:{keyword_id:data.id}}" outlined color="primary"
                 class="font-weight-bold" elevation="0"
                 style="border-radius: 10px">
            دریافت خروجی
          </v-btn>
        </div>
        <div class="mr-3" v-if="data.hasOwnProperty('id')">
          <v-btn small @click="openModalDelete" color="red" class="white--text font-weight-bold" elevation="0"
                 style="border-radius: 10px">
            حذف کلید‌واژه
          </v-btn>
        </div>

        <div class="mr-3" v-if="data.hasOwnProperty('id')">
          <v-btn small @click="editKeyword" color="secondary" class="font-weight-bold" elevation="0"
                 style="border-radius: 10px">
            ثبت تغیرات
          </v-btn>
        </div>
        <div class="mr-3" v-if="!data.hasOwnProperty('id')">
          <v-btn small @click="storeKeyword" color="secondary" class="font-weight-bold" elevation="0"
                 style="border-radius: 10px">
            ثبت کلید واژه
          </v-btn>
        </div>

      </div>
    </v-form>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import DeleteModal from "@/components/Modal/DeleteModal";

export default {
  components: {DeleteModal},
  props: {
    keyword: Object,
  },
  data() {
    return {
      data: {},
      deleted_id: null,
      deleteDialog: false,
      rejection: {},
      social_medias: [
        {
          "id": 6,
          "key": "reddit",
          "name": "ردیت",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 3,
          "key": "HackerNews",
          "name": "هکرنیوز",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 4,
          "key": "twitter",
          "name": "توئیتر",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 1,
          "key": "linkedin",
          "name": "لینکدین",
          "created_at": "2024-07-25 12:39:44",
          "updated_at": "2024-07-25 12:39:44",
        },
        {
          "id": 5,
          "key": "Quora",
          "name": "کوارا",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10",
        },
        {
          "id": 7,
          "key": "news",
          "name": "وب سایت‌های خبری",
          // "state": 'coming_soon',
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 9,
          "key": "instagram",
          "name": "اینستاگرام",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 8,
          "key": "instagram",
          "name": "تلگرام",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 10,
          "key": "facebook",
          "name": "فیس‌بوک",
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
        {
          "id": 11,
          "key": "youtube",
          "name": "یوتیوب",
          "state": 'coming_soon',
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },

        {
          "id": 12,
          "key": "facebook",
          "name": "تیک‌تاک",
          "state": 'coming_soon',
          "created_at": "2024-07-28 19:23:10",
          "updated_at": "2024-07-28 19:23:10"
        },
      ],
      social_media_id: null,
    }
  },
  computed: {
    allowedSubscription() {
      let temp = this.social_medias
      if (this.subscription) {
        let allowedSocialMedia = this.subscription.subscription.subscription_social_medias
        temp.forEach((item) => {
          if (allowedSocialMedia.find(social => social.id == item.id)) {
            item.disabled = false
          } else {
            item.disabled = true
          }
        })
      } else {
        temp.forEach((item) => {
          item.disabled = true
        })
      }

      return temp
    }
  },
  mounted() {
    this.data = this.keyword
    this.social_media_id = this.keyword.social_media?.id
    this.rejection = this.keyword.user_keyword_rejection[0]
  },
  methods: {
    limitSelection() {
      if (this.data.included_lang.length >= 3) {
        this.data.included_lang.splice(4); // Keep only the first 3 selected items
      }
    },
    limitSelectionExclude() {
      if (this.data.excluded_lang.length >= 3) {
        this.data.excluded_lang.splice(4); // Keep only the first 3 selected items
      }
    },
    storeKeyword() {
      if (this.$refs.keyword.validate()) {
        EventBus.$emit('setLoading', true)
        let payload = {
          socialMediaId: this.social_media_id,
          pattern: this.data.pattern,
          searchComment: this.data.search_commnet,
          searchPost: this.data.search_post,
          exclude_keyword: this.data.exclude_keyword,
          include_lang: this.data.included_lang ?? [],
          exclude_lang: this.data.excluded_lang ?? [],
          public_group_link: this.data.public_group_link ?? null
        }
        if (this.social_media_id != 1 && this.social_medias != 5) {
          payload.exclude_user = this.data.exclude_user
          payload.include_user = this.data.include_user
        }
        this.requesting('keyword', 'insertKeyword', {}, payload).then(() => {
          EventBus.$emit('notify', {text: 'کلمه کلیدی شما اضافه شد', color: 'green'})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
          this.$nextTick().then(() => {
            this.$emit('afterAdd')
          })
        })
      }
    },
    editKeyword() {
      if (this.$refs.keyword.validate()) {
        EventBus.$emit('setLoading', true)
        let payload = {
          socialMediaId: this.social_media_id,
          pattern: this.data.pattern,
          searchComment: this.data.search_commnet,
          searchPost: this.data.search_post,
          status: !!this.data.status,
          exclude_keyword: this.data.exclude_keyword,
          include_lang: this.data.included_lang ?? [],
          exclude_lang: this.data.excluded_lang ?? [],
          public_group_link: this.data.public_group_link ?? null
        }
        if (this.social_media_id != 1 && this.social_medias != 5) {
          payload.exclude_user = this.data.exclude_user
          payload.include_user = this.data.include_user
        }
        this.requesting('keyword', 'editKeyword', {
          id: this.data.id
        }, payload).then((resp) => {
          EventBus.$emit('notify', {text: 'غییرات کلیدواژه انجام شد', color: 'green'})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
          this.$nextTick().then(() => {
            this.$emit('afterEdit')
          })
        })
      }
    },
    openModalDelete() {
      this.deleteDialog = true
    },
    deleteKeyword() {
      this.deleteDialog = false
      EventBus.$emit('setLoading', true)
      this.requesting('keyword', 'deleteKeyword', {id: this.data.id}).then(() => {
        EventBus.$emit('notify', {text: 'کلمه کلیدی حذف شد', color: 'green'})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
        this.$emit('afterDelete')
      })
    }
  }
}
</script>

<style>
.inputBox .v-label {
  text-align: right !important;
}
</style>